import type { ComponentType } from 'react'
import type { VideoRestriction } from '@etf1-interne/tf1info_types_news/generics'
import type { IPlayerVideo } from '../../atoms/PlayerVideo'
import dynamic from 'next/dynamic'
import { useContext, useEffect, useState } from 'react'
import { ApiResponseContext } from '../../../contexts/ApiResponseContext'
import { simpleDate } from '../../../helpers/time'
import { TagCommanderContext } from '../../../contexts/tagcommander'

const PlayerVideo: ComponentType<IPlayerVideo> = dynamic(
  () => import('../../atoms/PlayerVideo').then((module) => module.PlayerVideo),
  { ssr: true },
)

export interface IPlayerComingNext
  extends Omit<
    IPlayerVideo,
    'videoId' | 'comingNext' | 'infoComingNext' | 'onMediaEnd' | 'onComingNextClick'
  > {
  videoId: string
  advertisingTags: string[]
  containerId: string
  autoplay: boolean
  withPip: boolean
  restriction: VideoRestriction
}

export function PlayerComingNext({
  videoId,
  advertisingTags,
  ...restProps
}: IPlayerComingNext): JSX.Element {
  const { page } = useContext(ApiResponseContext)
  const { setVideosData } = useContext(TagCommanderContext)

  const [listNextVideo, setListNextVideo] = useState([{ videoId }])

  const handleNextVideo = () => {
    setListNextVideo((prevList) => (prevList.length ? prevList.slice(1) : prevList))
  }

  const apiFetch = async (): Promise<void> => {
    try {
      const res = await fetch(`/api/coming-next/?contentId=${page.id}&videoId=${videoId}`)
      if (!res.ok) {
        throw Error(res.statusText)
      }

      const listVideos = await res.json()

      const videos = listVideos?.videos?.length
        ? listVideos.videos.map(({ video, title, thumbnail, publishedAt }) => ({
            thumb: thumbnail,
            title: simpleDate(new Date(publishedAt)),
            description: title,
            duration: video.duration,
            videoId: video.video_id,
          }))
        : []

      setListNextVideo((prevList) => [...prevList, ...videos])
      setVideosData((prevData) =>
        prevData && listVideos?.tms ? { ...prevData, ...listVideos.tms } : prevData,
      )
    } catch (e) {
      console.error('PlayerComingNext call error', e)
    }
  }

  useEffect(() => {
    if (!advertisingTags?.length && page.id) {
      apiFetch()
    }
  }, [page?.id])

  return (
    <div>
      <PlayerVideo
        videoId={listNextVideo[0]?.videoId}
        comingNext
        infoComingNext={listNextVideo?.[1]}
        onMediaEnd={handleNextVideo}
        onComingNextClick={handleNextVideo}
        onMediaError={handleNextVideo}
        {...restProps}
      />
    </div>
  )
}
